<template>
    <div></div>
</template>
<script>
import { onIonViewWillEnter } from "@ionic/vue";
export default {
    components: {},
    props: {},
    data() {
        return {};
    },
    computed: {
        DangXuat() {
            return this.$store.state.TaiKhoan.DangXuat || false;
        },
    },
    created() {
        if (this.DangXuat) {
            this.$Core.AuthApi.Logout();
        } else {
            this.$router.push("/tabs/tab1");
        }
    },
    beforeUnmount() {
        this.$stopLoading;
    },
    methods: {},
    mounted() {
        setTimeout(() => {
            this.$startLoading;
        }, 0);
    },
};
</script>
<style scoped></style>
<style lang="scss" scoped></style>
